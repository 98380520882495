import "./PanelRedes.scss";

export default function PanelRedes() {
  return (
    <aside className="panel-redes">
      <ul>
        <li>
          <a href="https://www.facebook.com/elvientoblanco" target="_blank" rel="noreferrer">
            <i className="bi bi-facebook"></i>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/FEVBlanco" target="_blank" rel="noreferrer">
            <i className="bi bi-twitter"></i>
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UCfHTQRZbO6iD8MIfaqeHNbA/featured" target="_blank" rel="noreferrer">
            <i className="bi bi-youtube"></i>
          </a>
        </li>        
      </ul>
    </aside>
  );
}
