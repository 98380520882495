import fotoHeader from "../assets/img/about.jpg";
import "./About.scss";

export default function About() {
  return (
    <section>
      <img src={fotoHeader} className="fotoHeader" alt="Header" />
      <article className="container mt-2">
        <h2>Quienes Somos:</h2>
        <hr />
        <p>
          Somos la primer fundación de la patagonia dedicada a la formación continua e integral de los recursos humanos ligados a la actividad del transporte, tránsito y seguridad vial en todos los
          niveles de gestión, por medio del diactado de planes de capacitación, cursos, conferencias, jornadas de perfeccionamiento y edición de publicaciones certificados por FAIR LOGISTICS
          CERTIFICATION.
        </p>
        <p>
          Con más de 10 años de experiencia podemos decir que brindamos con total idoneidad cada uno de nuestros cursos, los cuales permiten fortalecer habilidades, dentro de las normas de
          comepetencia laboral, abarcando los requerimientos de los distintos sectores de cada actividad, en términos de criterio, conocimiento, desempeño y resultados.
        </p>
        <p>
          Obtuvo su personería jurídica y aprobado su estatuto social el 15 de junio del año 2012 por decreto provincial número 1186/12. Se encuentra dedicada a la formación continua e integral de los
          recursos humanos ligados a la actividad del transporte, tránsito y seguridad vial en todos los niveles de gestión para lograr la concientización de todos aquellos involucrados en las
          actividades anteriormente mencionadas dejando constancia de la dedicación a estos temas en el manual del conductor argentino.
        </p>
        <p>
          Tiene como misión lograr la concientización de todos aquellos involucrados tanto en el sector del transporte, tránsito y seguridad vial por medio del dictado de planes de capacitación,
          cursos, conferencias, jornadas de perfeccionamiento y edición de publicaciones. Sus valores son: la dedicación, honestidad, integridad, respeto, trasparencia, vocación de servicio, espíritu
          profesional, entusiasmo y responsabilidad social.
        </p>

        <p>
          Asimismo, como trabajadores en logística, tenemos la capacidad de coordinar el conjunto de medios y métodos necesarios para llevar a cabo la organización de una empresa o de brindar un
          servicio especialmente de distribución, facilitando con ello el comercio y todas las actividades que conforman un sistema, siendo el enlace entre la producción y los mercados, con el
          propósito de acortar las distancias que se encuentran representadas por el tiempo y la distancia.
        </p>

        <p>
          Por medio de nuestra administración logística y de la cadena de suministro cómo podemos ser parte de la gestión y planificación de todas las actividades relacionadas al abastecimiento y
          mantenimiento, como la integración de los departamentos de compras, producción, transporte, almacenaje, manutención y distribución.
        </p>

        <p>
          Tenemos la capacidad de colocar los productos adecuados (bienes y servicios) en el lugar adecuado, en el momento preciso y en las condiciones deseadas, contribuyendo lo máximo posible a la
          rentabilidad de la firma.
        </p>

        <p>Garantizamos la satisfacción de la demanda en las mejores condiciones de servicio, costo y calidad.</p>

        <p>
          Nos encargamos de la gestión de los medios necesarios para alcanzar este objetivo, (superficies, medios de transportes, informática, otros), y moviliza los recursos humanos que sean
          adecuados.
        </p>

        <p>
          Hacemos posible el menor costo que permita mejorar el margen de beneficio de la empresa. Por medio de la educación y capacitación logramos la seguridad que permite a la empresa evitar
          sanciones; pero también comunicar en temas actuales como el respeto del medio ambiente, los productos éticos, otros.
        </p>

        <p>
          Estos parámetros nos permiten observar el carácter estratégico (planes, prospectiva, responsabilidad y conocimiento), tácticos (organización de la empresa) y operacionales (ejecución); de la
          función logística.
        </p>
      </article>
    </section>
  );
}
