import fotoHeader from "../assets/img/contact.jpeg";
import "./Contacto.scss";
import { useState, useCallback } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function Contacto() {  
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = executeRecaptcha("yourAction");
    return token;
  }, []);

  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [msjEnviado, setMsgEnviado] = useState(false);

  var token = "";

  function isFormValid() {
    if (nombre !== "" && email !== "" && mensaje !== "") return true;

    return false;
  }

  const formSubmissionHandler = (event) => {
    event.preventDefault();
    console.log(nombre, email, telefono, mensaje);

    handleReCaptchaVerify().then((tokenGenerated) => {
      token = tokenGenerated;

      sendMessage();
    });
  };

  function sendMessage() {
    if (token === "") return;

    const message = {
      nombre: nombre,
      email: email,
      telefono: telefono,
      mensaje: mensaje,
      token: token,
    };

    fetch("https://elvientoblanco.org.ar/mail/mail.php", {
      method: "POST",
      body: JSON.stringify(message),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("Mensaje enviado correctamente!");
        setMsgEnviado(true);
        resetForm();
      });
  }

  function resetForm() {
    setNombre('');
    setEmail('');
    setTelefono('');
    setMensaje('');
  }

  function closeMensajeEnviadoAlert() {
    setMsgEnviado(false);
  }

  return (
    <section>
      <img src={fotoHeader} className="fotoHeader" alt="Header" />

      <section className="container mt-2">
        <h2>Pongase en contacto con nosotros:</h2>
        <hr />

        <section className="py-4 d-flex flex-column flex-lg-row gap-4">
          <article className="formulario-contacto">
            <form onSubmit={formSubmissionHandler} autoComplete="off">
              <div className="mb-3">
                <label htmlFor="txtNombre" className="form-label">
                  Nombre / Razón Social:
                </label>
                <input type="text" className="form-control" id="txtNombre" maxLength={50} name="nombre" onChange={(event) => setNombre(event.target.value)} value={nombre}/>
              </div>
              <div className="mb-3">
                <label htmlFor="txtEmail" className="form-label">
                  Email:
                </label>
                <input type="email" className="form-control" id="txtEmail" aria-describedby="emailHelp" name="email" onChange={(event) => setEmail(event.target.value)} value={email} />
                <div id="emailHelp" className="form-text">
                  Nunca compartiremos sus datos ni su email con nadie
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="txtTelefono" className="form-label">
                  Teléfono:
                </label>
                <input type="text" className="form-control" id="txtTelefono" maxLength={15} name="telefono" onChange={(event) => setTelefono(event.target.value)} value={telefono}/>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputPassword1" className="form-label">
                  Mensaje:
                </label>
                <textarea className="form-control" id="txtMensaje" rows="5" name="mensaje" onChange={(event) => setMensaje(event.target.value)} value={mensaje}></textarea>
              </div>
              <div className="alert alert-primary mb-3" role="alert">
                También puede enviarnos un correo electrónico a <strong>info@elvientoblanco.org.ar</strong>
              </div>

              <div className="alert alert-success mb-3 alert-dismissible fade show" role="alert" hidden={!msjEnviado}>
                ¡Mensaje enviado corectamente!
                <button type="button" className="btn-close" onClick={closeMensajeEnviadoAlert}></button>
              </div>

              <button type="submit" className="btn btn-primary py-3" disabled={!isFormValid()}>
                Enviar Mensaje
              </button>
            </form>
          </article>

          <aside className="d-lg-none">
            <h2>Mapa:</h2>
            <hr className="my-0" />
          </aside>

          <article className="mapa">
            <iframe
              title="Mapa"
              src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d1552.501271025882!2d-68.08159144130076!3d-38.90105718444512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sDomingo%20Savio%202930%20PIN%20-%20NQN!5e0!3m2!1ses-419!2sar!4v1653606489002!5m2!1ses-419!2sar"
              width="600"
              height="450"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="mw-100"
            ></iframe>
          </article>
        </section>
      </section>
    </section>
  );
}
