import Carrusel from "../components/Carrusel";
import Main from "./Main";

function Home() {
  return (
    <section>
      <Carrusel />
      <Main />
    </section>
  );
}

export default Home;
