import { Route, Routes } from "react-router";
import "./App.scss";
import Footer from "./pages/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import NavBar from "./components/NavBar";
import LeyYolanda from "./pages/LeyYolanda";
import Contacto from "./pages/Contacto";

function App() {
  return (
    <div className="contenedor">
      <NavBar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="ley-yolanda" element={<LeyYolanda />} />
        <Route path="contacto" element={<Contacto />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
