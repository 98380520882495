import "./LeyYolanda.scss";
import leyYolanda from "../assets/img/ley-yolanda.jpg";

export default function LeyYolanda() {
  return (
    <section>
      <img src={leyYolanda} className="fotoHeader" alt="Header" />

      <article className="container mt-2">
        <h2>Ley Yolanda:</h2>
        <hr />

        <h3>Formación integral en ambiente para las personas que se desempeñan en la función pública.</h3>
        <p>
          La Ley n.º 27592 o Ley Yolanda es una ley que tiene como objetivo garantizar la formación integral en ambiente, con perspectiva de desarrollo sostenible y con especial énfasis en cambio
          climático, para las personas que se desempeñan en la función pública. Fue sancionada el 17 de noviembre de 2020.
        </p>
        <p>
          El nombre es un homenaje a{" "}
          <a href="https://www.argentina.gob.ar/ambiente/accion/ley-yolanda/quien-fue-yolanda-ortiz" target="_blank" rel='noreferrer'>
            Yolanda Ortiz
          </a>{" "}
          quien fue la primera secretaria de Recursos Naturales y Ambiente Humano de Argentina y de América Latina, designada durante el gobierno de Juan Domingo Perón en el año 1973.
        </p>
      </article>

      <article className="container mt-4">
        <h3>Lineamientos generales para la capacitación en ambiente:</h3>        

        <p>
          Los lineamientos generales <strong>constituyen la base para orientar el diseño de los itinerarios formativos en el marco de las capacitaciones en ambiente </strong>destinadas a quienes se
          desempeñan en la función pública. Fueron diseñados de manera participativa, conforme el art. 4 de la Ley 27.592 y en el entendimiento de que la construcción participativa garantiza procesos
          transformadores.
        </p>
        <p>
          En este sentido han sido fundamentales los aportes provenientes de 244 instituciones y organizaciones, siendo 113 instituciones científico académicas y 131 organizaciones de la sociedad
          civil con representatividad de las 24 jurisdicciones nacionales. Participaron en su validación el Consejo Interuniversitario Nacional (res. 1613/21) y el Consejo Federal de Medio Ambiente,
          garantizando el sentido federal de los mismos.
        </p>
      </article>
    </section>
  );
}
