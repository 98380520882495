import "./Main.scss";

function Main() {
  return (
    <div>
      <section className="d-flex flex-column flex-xl-row main">
        <article className="panel-foto-seguridad-vial">
          <img src={require("../assets/img/operacion-equipos-viales.jpeg")} className="imagen-zoom w-100" alt="Seguridad Vial" />
        </article>
        <article className="panel-texto-bienvenida">
          <h2>¡Bienvenidos!</h2>
          <hr />
          <p className='italic'>
            En primer lugar queremos darle la bienvenida a nuestra nueva página web e invitarlos a compartir con nosotros conocimientos y experiencias, agradeciendole la confianza depositada en
            nosotros. Sabemos que NO es un plazo fijo, lo tenemos que renovar día a día. Es por ello que vamos a compartir las nuevas claves, medios y métodos para incrementar en todos nosotros los
            aspectos relacionados a la Seguridad en el Tránsito Transporte y Seguridad Vial.
          </p>
          <p className='italic'>
            Nuestra propuesta continúa dirigida a personas que necesitan formarse integralmente en el Tránsito Transporte y Seguridad Vial o que requieran de una recualificación de las competencias
            laborales y de sus saberes productivos motivados por cambios organizacionales y/o por demandas de calidad y eficiencia del mercado de trabajo y/o por exigencias sociales y la necesidad de
            reactualizar conocimientos de las normas de tránsito y buenas costumbres.
          </p>
          <p className='italic'>Quedamos a su disposición para cualquier tipo de duda o pregunta que quieran realizarnos sin ningún tipo de compromiso.</p>
        </article>
      </section>
      <aside>
        <img src={require("../assets/img/marcas.jpg")} className="w-100" alt="Marcas" />
      </aside>
    </div>
  );
}

export default Main;
