import "./Footer.scss";
import bannerUnicen from "../assets/img/banners/unicen.png";
import bannerOmcypl from "../assets/img/banners/omcypl.png";
import PanelRedes from "../components/PanelRedes";

export default function Footer() {
  return (
    <footer className="d-flex flex-xl-row justify-content-evenly align-items-stretch">
      <aside className="order-xl-2 banners">
        <a href="https://www.unicen.edu.ar/" target="_blank" rel="noreferrer">
          <img src={bannerUnicen} alt="Unicen" />
        </a>
        <a href="https://omcpl.org/" target="_blank" rel="noreferrer">
          <img src={bannerOmcypl} alt="Organizacion Mundial de Ciudades y Plataformas Logísticas" />
        </a>
      </aside>

      <div className="separador order-xl-3"></div>

      <aside className="order-xl-4 panel-contacto">
        <h3>Contacto:</h3>
        <ul>
          <li>
            <i className="bi bi-telephone-fill me-2"></i> +54 9 299-6377677
          </li>
          <li>
            <i className="bi bi-house-door-fill me-2"></i> Domingo Savio 2930 PIN - NQN
          </li>
          <li>
            <i className="bi bi-envelope-fill me-2"></i> info@elvientoblanco.org.ar
          </li>
        </ul>
      </aside>

      <div className="separador order-xl-1"></div>

      <aside className="order-xl-0 panel-logo-redes">
        <img src={require("../assets/img/isotipo.png")} className="isotipo" alt="Logo El Viento Blanco" />
        <div className="mt-4">
          <PanelRedes />
        </div>
        <aside className="panel-copyright">
          <span>&copy; 2022 - Fundación El Viento Blanco</span>
          <span>info@elvientoblanco.org.ar</span>
        </aside>
      </aside>
    </footer>
  );
}
