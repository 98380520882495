import "./Carrusel.scss";
import foto1 from "../assets/img/carrusel/1.jpg";
import foto2 from "../assets/img/carrusel/2.jpg";
import foto3 from "../assets/img/carrusel/3.jpg";
import foto4 from "../assets/img/carrusel/4.jpg";

function Carrusel() {
  return (
    <div id="carrusel-home" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        <button type="button" data-bs-target="#carrusel-home" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carrusel-home" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carrusel-home" data-bs-slide-to="2" aria-label="Slide 3"></button>
        <button type="button" data-bs-target="#carrusel-home" data-bs-slide-to="3" aria-label="Slide 4"></button>
        
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={foto1} className="d-block carrusel-imagen" alt="..." />
        </div>
        <div className="carousel-item">
          <img src={foto2} className="d-block carrusel-imagen" alt="..." />
        </div>
        <div className="carousel-item">
          <img src={foto3} className="d-block carrusel-imagen" alt="..." />
        </div>
        <div className="carousel-item">
          <img src={foto4} className="d-block carrusel-imagen" alt="..." />
        </div>
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carrusel-home" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carrusel-home" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}

export default Carrusel;
