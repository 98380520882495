import "./NavBar.scss";
import Logo from "../assets/img/isotipo.png";
import { NavLink } from "react-router-dom";
import PanelRedes from "./PanelRedes";

function NavBar() {
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-dark">
        <div className="container">
          <div className="logo me-xl-5">
            <img src={Logo} alt="Isotipo"></img>
            <h1 className="titulo-logo">
              Fundación <br /> El Viento Blanco
            </h1>
          </div>
          <button
            id="toggler"
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <div className="w-100 d-flex flex-column flex-xl-row justify-content-between">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink className="nav-link" aria-current="page" to="/">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/about">
                    La Fundación
                  </NavLink>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://elvientoblanco.blogspot.com/" target={"_blank"} rel="noreferrer">
                    Noticias
                  </a>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/ley-yolanda">
                    Ley Yolanda
                  </NavLink>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle disabled" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Servicios
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li>
                      <a className="dropdown-item" href="#">
                        Escuela De Manejo
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        LINTI - Mercancías Peligrosas
                      </a>
                    </li>
                    {/* <li>
                    <hr className="dropdown-divider" />
                  </li> */}
                    <li>
                      <a className="dropdown-item" href="#">
                        Trabajo En Altura
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Trabajo En Espacios Confinados
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Primeros Auxilios
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Lucha Contra el Fuego
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Asesoramiento Empresarial
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/contacto">
                    Contacto
                  </NavLink>
                </li>
              </ul>
              <aside className="ms-4">
                <PanelRedes />
              </aside>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default NavBar;
